import {Formik} from 'formik';

import {FormWithButtons} from '@pac/platform-ui-components';

import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {stage} from '../../../config/stage';
import {NEXTPolicyStoreSchema} from "../types.ts";
import {AddRecordSchema} from "./AddRecordSchema.ts";
import {AddRecordFormComponent} from './AddRecordFormComponent.tsx';

type RecordFormProps = {
    record: object,
    handleSubmit: (values: NEXTPolicyStoreSchema) => void
}
export const AddRecordForm = ({
                                  record,
                                  handleSubmit
                              }: RecordFormProps) => {
    const navigate = useNavigate();

    const {id: policyStoreId} = useParams();

    const onSubmit = (values: { namespace: string }) => {

        const basicSchema = {} as { [key: string]: any };
        basicSchema[values.namespace] = {
            commonTypes: {},
            actions: {},
            entityTypes: {}
        }

        const payload = {
            id: '',
            policyStoreId: policyStoreId,
            schema: JSON.stringify(basicSchema, null, 2)
        } as NEXTPolicyStoreSchema
        handleSubmit(payload);

        navigate(`/policy-stores/${policyStoreId}/schema/#entity-types`);
    };

    const onCancel = () => {
        navigate(`/policy-stores/${policyStoreId}/schema/#entity-types`);
    };


    let initialValues = {} as { namespace: string };

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={AddRecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting}
                                 handleCancel={onCancel} debug={false && stage === 'ide'} {...rest}  >
                    <AddRecordFormComponent/>
                </FormWithButtons>)}
        </Formik>
    );
};

