import {Outlet} from 'react-router-dom';

import {
    PlatformFooter,
    PlatformHeader,
    PlatformHeaderToolbar
} from '@pac/platform-ui-components';

import {
    AppShell,
    Box,
    Group,
    Stack,
    Title,
} from '@mantine/core';
import classes from "./GuestLayout.module.css";
import {ColorSchemeIcon} from '../components/ColorSchemeIcon';
import {SignInButton} from './SignInButton';

export const GuestLayout = () => {

    let header = <PlatformHeader/>;

    const toolbar = <PlatformHeaderToolbar
        tools={
            [
                <SignInButton/>,
                <ColorSchemeIcon/>
            ]
        }/>;

    header = <PlatformHeader toolbar={toolbar}/>;

    return (
        <AppShell
            header={{height: 70}}
            padding="md"
        >
            <AppShell.Header>
                {header}
            </AppShell.Header>

            <AppShell.Main className={classes.main}>
                <Box className={classes.hero}>
                    <Stack gap={'xl'}>
                        <Title order={1}>Welcome to PAC Cloud Permissions!</Title>
                        <Title order={3}>Please sign in to continue.</Title>
                        <Group mt={'xl'} justify={'center'}>
                            <SignInButton/>
                        </Group>
                    </Stack>
                    <Outlet/>
                </Box>
            </AppShell.Main>

            <AppShell.Footer p={'md'}>
                <PlatformFooter/>
            </AppShell.Footer>

        </AppShell>
    )


};
