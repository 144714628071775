import {
    Field,
    FormikProps
} from 'formik';

import {I18n} from '@aws-amplify/core';

import {PacFormikExpandableJsonInput} from '@pac/platform-ui-components';
import {NEXTPolicyStoreSchema} from "../types.ts";


export const FormComponent = (_: FormikProps<NEXTPolicyStoreSchema>) => {

    return (
        <Field
            name='schema'
            placeholder={I18n.get('Schema')}
            component={PacFormikExpandableJsonInput}
            autosize={true}
            minRows={10}
        />
    );
};
