import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../../policies/services/PoliciesService';

import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {buildPolicyBody} from "../../../utils/PolicyHelper";
import {
    NEXTPolicy,
    NEXTPolicyDefinition,
    NEXTPolicyFormValues
} from "../types.ts";
import {moduleConfig} from "../config";
import {getConditions} from "../../../utils/PolicyParser.ts";
import {recordSelector} from "../../policy-stores";


const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};


const normalizeRecord = (policyStoreId: string, record: NEXTPolicy) => {

    let conditions = [] as { key: string, value: string }[];

    const myRecord = Object.assign({}, record as any)

    // conditions = getConditionsFromPolicy(myRecord)

    if (myRecord.definition && myRecord.definition.static && myRecord.definition.static.statement) {
        const statement = myRecord.definition.static.statement
        conditions = getConditions(statement)
    }

    return {
        ...record,
        ...{
            id: record.policyId,
            policyStoreId: policyStoreId,
            policyConditions: conditions
        }
    };
};

// genenrates pending, fulfilled and rejected
export const fetchPolicies = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    ({
         policyStoreId
     }: { policyStoreId: string }) => {

        return fetchRecords(policyStoreId)
            .then((response) => {
                return response?.map((record) => {
                    return normalizeRecord(policyStoreId, record);
                });
            });
    },
);

export const fetchPolicy = createAsyncThunk(
    `${entityKey}/fetch`,
    ({
         policyStoreId,
         id
     }: {
        policyStoreId: string,
        id: string
    }) => {

        return fetch(policyStoreId, id)
            .then((response) => {
                return normalizeRecord(policyStoreId, response as NEXTPolicy);
            });
    });


export const savePolicy = createAsyncThunk(
    `${entityKey}/add`,
    ({
         policyStoreId,
         payload
     }: {
        policyStoreId: string,
        payload: NEXTPolicyFormValues
    }, {getState}) => {

        console.debug('payload in save policy', payload)

        const state = getState()

        // // update principal entity type name
        // const {record: principalEntityType} = recordSelector(state, payload.principal.entityType)
        // payload.principal.entityType = principalEntityType.name
        //
        // // update resource entity type name
        // const {record: resourceEntityType} = recordSelector(state, payload.resource.entityType)
        // payload.resource.entityType = resourceEntityType.name
        //
        // console.debug('payload in save policy after', payload)

        const {record: policyStore} = recordSelector(state, policyStoreId)

        const policyBody = buildPolicyBody(policyStore.namespace, payload.policyEffect, payload.scope, payload.conditions)

        console.debug('assembled policy body', policyBody)

        const definition: NEXTPolicyDefinition = {
            static: {
                statement: policyBody,
                description: payload.description
            }
        };

        return add(policyStoreId, definition)
            .then((response) => normalizeRecord(policyStoreId, response as NEXTPolicy));
    });

export const updatePolicy = createAsyncThunk(
    `${entityKey}/update`,
    ({
         policyStoreId,
         id,
         record
     }: {
        policyStoreId: string,
        id: string,
        record: NEXTPolicyFormValues
    }, {getState}) => {

        const state = getState()
        const {record: policyStore} = recordSelector(state, policyStoreId)

        if (record.conditions) {
            // remove undefined elements from actions array
            record.conditions.when = record.conditions?.when.filter((condition: any) => condition !== undefined)
        }

        const policyBody = buildPolicyBody(policyStore.namespace, record.policyEffect, record.scope, record.conditions)

        const definition: NEXTPolicyDefinition = {
            static: {
                statement: policyBody,
                description: record.description,
            }
        };

        return update(policyStoreId, id, definition)
            .then((response) => {
                return normalizeRecord(policyStoreId, response as NEXTPolicy);
            });
    },
);

export const deletePolicy = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    ({
         policyStoreId,
         id
     }: {
        policyStoreId: string,
        id: string
    }) => {

        return deleteRecord(policyStoreId, id)
            .then(() => id);
    },
);

const policiesSlice = createSlice({
                                      name: entityKey,
                                      initialState,
                                      reducers: {
                                          clearState: () => initialState,
                                      },
                                      extraReducers: (builder) => {
                                          // FETCH MANY
                                          fetchManyCase(builder, fetchPolicies, entityKey);

                                          // FETCH ONE
                                          fetchOneCase(builder, fetchPolicy);

                                          // SAVE ONE
                                          saveOneCase(builder, savePolicy);

                                          // UPDATE ONE
                                          updateOneCase(builder, updatePolicy);

                                          // DELETE ONE
                                          deleteOneCase(builder, deletePolicy);
                                      },
                                  });

export default policiesSlice.reducer;
export const {clearState} = policiesSlice.actions;
