import {
    Field,
    useFormikContext
} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    Center,
    Fieldset,
    Grid,
    SimpleGrid,
    Text
} from '@mantine/core';

import {
    PacFormikInput,
    PacFormikRadioGroup
} from '@pac/platform-ui-components';
import {EntityTypeNamesAutocomplete} from "../../../entity-types/inputs/EntityTypeNamesAutocomplete.tsx";
import {UsersAutocomplete} from "../../../users";
import {TenantsAutocomplete} from "../../../tenants/inputs/TenantsAutocomplete.tsx";
import {PermissionGroupNamesAutocomplete} from "../../../permission-groups/inputs/PermissionGroupNamesAutocomplete.tsx";
import {NEXTPolicyFormValues} from "../../types.ts";


export const PolicyPrincipalsScope = () => {

    const {
        values,
    } = useFormikContext<NEXTPolicyFormValues>()

    // console.debug('PolicyScope values', values)

    let principalEntityIdComponent = null;
    if (values && values.scope.principals.value) {
        switch (values.scope.principals.value.entityType) {
            case 'User':
                principalEntityIdComponent = UsersAutocomplete
                break;
            case 'Tenant':
                principalEntityIdComponent = TenantsAutocomplete
                break;
            default:
                principalEntityIdComponent = PacFormikInput
        }
    }

    let groupOfPrincipalEntityIdsComponent = null;
    if (values && values.scope.principals.value) {
        switch (values.scope.principals.value.entityType) {
            case 'CognitoGroup':
                groupOfPrincipalEntityIdsComponent = PermissionGroupNamesAutocomplete
                break;
            default:
                groupOfPrincipalEntityIdsComponent = PermissionGroupNamesAutocomplete
        }
    }


    return (
        <Fieldset fw={600} tt={'uppercase'} legend={'Principals Scope'}>

            <Field
                name="scope.principals.type"
                label={''}
                options={[{
                    label: 'All',
                    value: 'all'
                }, {
                    label: 'Group of principals',
                    value: 'group'
                }, {
                    label: 'Specific principal',
                    value: 'specific'
                }]}
                component={PacFormikRadioGroup}
                my={'xl'}
            />

            {values.scope.principals.type === 'specific' ?
                <>
                    <Text fw={600}>Principal</Text>
                    <SimpleGrid cols={2}>
                        <Field
                            name='scope.principals.value.entityType'
                            placeholder={I18n.get('Principal Entity Type')}
                            component={EntityTypeNamesAutocomplete}
                            my={'xl'}
                        />
                        {values.scope.principals.value && values.scope.principals.value.entityType != '' ?
                            <Field
                                name='scope.principals.value.entityId'
                                placeholder={I18n.get('Principal Entity ID')}
                                component={principalEntityIdComponent}
                                my={'xl'}
                            /> : null}
                    </SimpleGrid></> : null
            }

            {values.scope.principals.type === 'group' ?
                <>
                    <Text fw={600}>Principal</Text>
                    <Grid>
                        <Grid.Col span={5}>
                            <Field
                                name='scope.principals.value.entityType'
                                placeholder={I18n.get('Principal Entity Type')}
                                component={EntityTypeNamesAutocomplete}
                                my={'xl'}
                            />
                        </Grid.Col>
                        {values.scope.principals.value && values.scope.principals.value.entityType != '' ? (
                            <>
                                <Grid.Col span={1}>
                                    <Center>
                                        <Text fw={800} my={'xl'}>IN</Text>
                                    </Center>
                                </Grid.Col>

                                <Grid.Col span={5}>
                                    <Field
                                        name='scope.principals.value.entityId'
                                        placeholder={I18n.get('Principal Entity ID')}
                                        component={groupOfPrincipalEntityIdsComponent}
                                        my={'xl'}
                                    /></Grid.Col>
                            </>
                        ) : null}
                    </Grid>
                </> : null
            }

        </Fieldset>
    );
};
