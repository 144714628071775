import {
    Outlet,
    useNavigate
} from 'react-router-dom';

import {
    PlatformFooter,
    PlatformHeader,
    PlatformHeaderProfileButton,
    PlatformHeaderToolbar,
    PlatformLoader,
    PlatformNavbarMode
} from '@pac/platform-ui-components';


import {PortalNavbar} from './menu-items/pages/PortalNavbar';

import {
    ActionIcon,
    AppShell,
    Box,
} from '@mantine/core';

import {stage} from '../config/stage';

import {FaSignOutAlt} from 'react-icons/fa';
import classes from './UserLayout.module.css';
import {
    Suspense,
    useState
} from "react";
import {ColorSchemeIcon} from '../components/ColorSchemeIcon';
import {useDisclosure} from "@mantine/hooks";
import {AiOutlineLayout} from 'react-icons/ai';

export const UserLayout = (
    {
        givenName,
        familyName,
        email
    }: {
        givenName: string,
        familyName: string,
        email: string
    }) => {

    const [mode, setMode] = useState(PlatformNavbarMode.MEDIUM)
    const [navbarWidth, setNavbarWidth] = useState(300)

    const navigate = useNavigate();

    let header = <PlatformHeader/>;

    const [opened, {toggle}] = useDisclosure();

    const toolbar = <PlatformHeaderToolbar
        tools={
            [
                <PlatformHeaderProfileButton
                    stage={stage} user={{
                    name: [givenName, familyName].join(' '),
                    'email-address': email
                } as { name: string, 'email-address': string }}
                    showDefaultMenuItems={true}
                    additionalMenuItems={[{
                        title: 'Sign Out',
                        icon: <FaSignOutAlt size={20}/>,
                        onClick: () => navigate('/signout'),
                    }]}/>,
                <ActionIcon><AiOutlineLayout size={'1.5em'} onClick={toggle}
                                             title={'Toggle Navbar Layout'}/></ActionIcon>,
                <ColorSchemeIcon/>
            ]
        }/>;

    header = <PlatformHeader toolbar={toolbar}/>;

    const changeMode = (mode: PlatformNavbarMode) => {
        setMode(mode)
        if (mode === 'medium') {
            setNavbarWidth(300)
        } else {
            setNavbarWidth(70)
        }
    }

    return (
        <AppShell
            header={{height: 70}}
            footer={{height: 95}}
            navbar={{
                width: navbarWidth,
                breakpoint: 'sm',
                collapsed: {
                    desktop: opened,
                    mobile: !opened
                }
            }}
        >

            <AppShell.Header>
                {header}
            </AppShell.Header>

            <AppShell.Navbar>
                <PortalNavbar mode={mode} changeMode={changeMode}/>
            </AppShell.Navbar>

            <AppShell.Main>
                <Suspense fallback={<PlatformLoader/>}>
                    <Box className={classes.main}>
                        <Outlet/>
                    </Box>
                </Suspense>
            </AppShell.Main>

            <AppShell.Footer p={'md'}>
                <PlatformFooter/>
            </AppShell.Footer>

        </AppShell>
    );
};
