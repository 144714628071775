import {
    PlatformNavbar,
    PlatformNavbarMode,
    PlatformPropertiesCardSkeleton
} from "@pac/platform-ui-components";
import {getMenuItems} from "../data/menus.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import config from "../../../config";


export const PortalNavbar = ({
                                 mode,
                                 changeMode
                             }: {
    mode: PlatformNavbarMode,
    changeMode: (mode: PlatformNavbarMode) => void
}) => {


    const menuItems = getMenuItems();

    const {
        authorizing,
        allowedActions,
    } = useVerifiedPermissions();

    if (authorizing) {
        return <PlatformPropertiesCardSkeleton/>;
    }


    const authorizedMenuItems = menuItems.filter((item) => {
        return allowedActions.includes(item.rbac as string);
    });

    if (Array.isArray(authorizedMenuItems) && authorizedMenuItems.length === 0) {
        return null;
    }

    return (<PlatformNavbar
        appTitle={config.app.name}
        menuItems={authorizedMenuItems}
        mode={mode}
        changeMode={changeMode}/>);
};

