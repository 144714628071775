import {NavLink} from "react-router-dom";
import {PermissionGroup} from "../types.ts";
import {PlatformPropertiesCard} from "@pac/platform-ui-components";
import {Stack} from "@mantine/core";


export const Profile = ({record}: { record: PermissionGroup }) => {

    const properties = [
        {
            label: 'Group Name',
            value: record.name,
            span: 6
        },
        {
            label: 'Policy Store ID',
            value: <NavLink
                to={`/policy-stores/${record.policyStoreId}`}>{record.policyStore ? record.policyStore.name : record.policyStoreId}</NavLink>,
            span: 6
        },
        {
            label: 'Entity Type Name',
            value: record.entityTypeName,
            span: 6
        },
        {
            label: 'Entity Identifier',
            value: record.entityIdentifier,
            span: 6
        },
        {
            label: 'Description',
            value: record.description,
            span: 12
        },
    ];

    const properties2 = [
        {
            label: 'Created By',
            value: record.createdByUsername,
            span: 6
        },
        {
            label: 'Managed By',
            value: record.managedByUsername,
            span: 6
        }, {
            label: 'Created',
            value: record.created ? record.created : 'n/a',
            formatAs: 'Timestamp',
            span: 6
        },
        {
            label: 'Last Updated',
            value: record.updated ? record.updated : 'n/a',
            formatAs: 'Timestamp',
            span: 6
        },
    ];

    return (
        <Stack>
            <PlatformPropertiesCard properties={properties}/>
            <PlatformPropertiesCard properties={properties2}/>
        </Stack>
    );


};
