import {
    Field,
    useFormikContext
} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    Fieldset,
    Text
} from '@mantine/core';

import {PacFormikRadioGroup} from '@pac/platform-ui-components';

import {ActionNamesMultiSelect} from '../../../actions/inputs/ActionNamesMultiSelect';
import {NEXTPolicyFormValues} from "../../types.ts";


export const PolicyActionsScope = () => {

    const {
        values,
    } = useFormikContext<NEXTPolicyFormValues>()


    return (
        <Fieldset fw={600} tt={'uppercase'} legend={'Actions Scope'}>

            <Field
                name="scope.actions.type"
                label={''}
                options={[{
                    label: 'All',
                    value: 'all'
                }, {
                    label: 'Specific set of actions',
                    value: 'set'
                }]}
                component={PacFormikRadioGroup}
                my={'xl'}
            />

            {values.scope.actions.type === 'set' ?
                <>
                    <Text fw={600}>Actions</Text>
                    <Field
                        name='scope.actions.value'
                        label=''
                        placeholder={I18n.get('Actions')}
                        component={ActionNamesMultiSelect}
                        my={'xl'}
                    />
                </> : null}

        </Fieldset>
    );
};
