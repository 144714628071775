import {Field} from 'formik';

import {I18n} from '@aws-amplify/core';
import {PolicyStoreNamespaceInput} from "../inputs/PolicyStoreNamespaceInput.tsx";


export const AddRecordFormComponent = () => {

    return (
        <Field
            name='namespace'
            label={I18n.get('Schema Namespace')}
            placeholder={I18n.get('Namespace')}
            component={PolicyStoreNamespaceInput}
            disabled={true}
        />
    );
};
