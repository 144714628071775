export const listPolicyStores = `
query listPolicyStores($filter: PolicyStoresFilterInput, $limit: Int, $nextToken: String) {
  listPolicyStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      validationSettings {
        mode
      }
      namespace
      arn
      deploymentEnvironmentId
      deploymentEnvironment {
        platformAccount {
          name
        }
        region
      }
      identityProviderId
      identityProvider {
        name
      }
      policyStoreType
      created
      createdByUsername
      updated
    }
  }
}
`


export const getPolicyStore = `
  query getPolicyStore($id: ID!) {
    getPolicyStore(id: $id){
      id
      name
      description
      validationSettings {
        mode
      }
      namespace
      arn
      deploymentEnvironmentId
      deploymentEnvironment {
        platformAccount {
          name
        }
        region
      }
      identityProviderId
      identityProvider {
        name
      }
      policyStoreType
      created
      createdByUsername
      updated
    }
  }  
`;