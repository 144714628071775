import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          name: Yup.string()
                                                   .required('name is required'),
                                          namespace: Yup.string()
                                                        .required('namespace is required'),
                                          validationSettings: Yup.object({
                                                                             mode: Yup.string()
                                                                         }),
                                      });
