export const getPolicyActions = (policyBody: string) => {
    // console.debug(policyBody)

    // let re = new RegExp('action in \\[(?:Console::Action::"(.*)", )*(?:Console::Action::"(.*)")*\\]')
    // let re = new RegExp('action in \\[(Console::Action::".*", *Console::Action::".*")\\]')

    const regex = /Action::"(\w*)"/g
    const actionMatches = policyBody.match(regex)


    let actions: string[] = []

    if (Array.isArray(actionMatches)) {

        actions = actionMatches.map(match => {
            const trimmed = match.substring(0, match.length - 1)
            const prefix = "Action::\""
            const result = trimmed.substring(prefix.length)
            return result
        })

    }

    return actions;

}

export const buildPolicyBody = (
    namespace: string,
    policyEffect: string | null,
    scope: {
        principals: {
            type: 'all' | 'group' | 'specific',
            value?: {
                        entityType: string,
                        entityId: string
                    } | null,
        },
        actions: {
            type: 'all' | 'set',
            value?: string[] | null
        },
        resources: {
            type: 'all' | 'group' | 'specific',
            value?: {
                        entityType: string,
                        entityId: string
                    } | null,
        },
    },
    conditions?: {
        when: { key: string, value: string }[],
        unless: { key: string, value: string }[]
    },
) => {


    let principalString = ''
    switch (scope.principals.type) {
        case 'all':
            principalString = 'principal'
            break
        case 'group':
            principalString = `principal in ${namespace}::${scope.principals.value?.entityType}::"${scope.principals.value?.entityId}"`
            break
        case 'specific':
            principalString = `principal == ${namespace}::${scope.principals.value?.entityType}::"${scope.principals.value?.entityId}"`
            break
    }


    let actionsString = ''
    switch (scope.actions.type) {
        case 'all':
            actionsString = 'action'
            break
        case 'set':
            actionsString = `action in [${scope.actions.value?.map(action => `${namespace}::Action::"${action}"`)
                                               .join(', ')}]`
            break
    }


    let resourceString = ''
    switch (scope.resources.type) {
        case 'all':
            resourceString = 'resource'
            break
        case 'group':
            resourceString = `resource in ${namespace}::${scope.resources.value?.entityType}::"${scope.resources.value?.entityId}"`
            break
        case 'specific':
            resourceString = `resource == ${namespace}::${scope.resources.value?.entityType}::"${scope.resources.value?.entityId}"`
            break
    }


    let conditionString = 'when { true };'
    if (typeof conditions !== 'undefined' && conditions.when.length > 0) {
        const whenConditions = conditions.when.map(condition => `${condition.key} == "${condition.value}"`)
                                         .join(' && ')
        conditionString = `when {\n` + `${whenConditions}\n` + `};\n`
    }

    if (typeof conditions !== 'undefined' && conditions.unless.length > 0) {
        const unlessConditions = conditions.unless.map(condition => `${condition.key} != "${condition.value}"`)
                                           .join(' && ')
        conditionString = `unless {\n` + `${unlessConditions}\n` + `};\n`

    }

    return `${policyEffect} (\n` +
        `    ${principalString},\n` +
        `    ${actionsString},\n` +
        `    ${resourceString}\n` +
        `)\n` +
        `${conditionString}`

}

export const buildTemplatePolicyBody = (namespace: string, principal: {
                                                                          entityType: string,
                                                                          entityId: string
                                                                      } | null, actions: string[], resource: {
                                                                                                                 entityType: string
                                                                                                                 entityId: string
                                                                                                             } | null) => {


    let principalString = 'principal == ?principal'
    if (typeof principal !== 'undefined' && principal !== null && principal.entityType !== null) {
        principalString = `principal == ${principal.entityType}::"${principal.entityId}"`
    }

    let actionsString = 'action'
    if (actions.length > 0) {
        const namespacedActions = actions.map(action => `${namespace}::Action::"${action}"`)
        actionsString = namespacedActions.join(', ')
    }

    let resourceString = 'resource == ?resource'
    if (typeof resource !== 'undefined' && resource !== null) {
        resourceString = resource ? `resource == ${resource.entityType}::"${resource.entityId}"` : 'resource == ?resource';
    }

    return `permit (\n` +
        `    ${principalString},\n` +
        `    action in [${actionsString}],\n` +
        `    ${resourceString}\n` +
        `);`
}