import {Stack} from '@mantine/core';
import {PolicyPrincipalsScope} from "./PolicyPrincipalsScope.tsx";
import {PolicyResourcesScope} from './PolicyResourcesScope.tsx';
import {PolicyActionsScope} from './PolicyActionsScope.tsx';


export const PolicyScope = () => {

    return (
        <Stack gap={'xl'}>
            <PolicyPrincipalsScope/>
            <PolicyActionsScope/>
            <PolicyResourcesScope/>
        </Stack>
    );
};
