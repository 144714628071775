export const createPermissionGroup = `
  mutation createPermissionGroup($input: CreatePermissionGroupInput!) {
    createPermissionGroup(input: $input){
      id
      name
      description
      policyStoreId
      entityTypeName
      entityIdentifier
      createdByUsername
      managedByUsername
      created
      updated
    }
  }  
`;

export const updatePermissionGroup = `
  mutation updatePermissionGroup($input: UpdatePermissionGroupInput!) {
    updatePermissionGroup(input: $input){
      id
      name
      description
      policyStoreId
      entityTypeName
      entityIdentifier
      createdByUsername
      managedByUsername
      created
      updated
    }
  }
`;

export const deletePermissionGroup = `
  mutation deletePermissionGroup($input: DeletePermissionGroupInput!) {
    deletePermissionGroup(input: $input){
      id
    }
  }  
`;
