import {Formik} from 'formik';

import {RecordSchema} from './RecordSchema';

import {FormWithButtons} from '@pac/platform-ui-components';

import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {stage} from '../../../config/stage';
import {getPolicyActions} from "../../../utils/PolicyHelper";
import {
    NEXTPolicy,
    NEXTPolicyFormValues
} from "../types.ts";
import {FormComponent} from "./FormComponent.tsx";


export const RecordForm = (
    {
        record,
        handleSubmit
    }: {
        record: NEXTPolicy,
        handleSubmit: (values: NEXTPolicyFormValues) => void
    }) => {

    const navigate = useNavigate();

    const {id: policyStoreId} = useParams();

    const onSubmit = (values: NEXTPolicyFormValues) => {
        handleSubmit(values);
        navigate(`/policy-stores/${policyStoreId}/permissions/#policies`);
    };

    const onCancel = () => {
        navigate(`/policy-stores/${policyStoreId}/permissions/#policies`);
    };


    let initialValues = {
        policyEffect: 'permit',
        scope: {
            principals: {
                type: 'all',
                value: null
            },
            actions: {
                type: 'all',
                value: []
            },
            resources: {
                type: 'all',
                value: null
            }
        },
        conditions: {
            when: [],
            unless: []
        },
        description: ''
    } as NEXTPolicyFormValues;

    if (record) {
        console.debug(record)

        if (record.definition && record.definition.static && record.definition.static.statement) {
            const actions = getPolicyActions(record.definition.static.statement)
            initialValues.scope.actions.type = 'set'
            initialValues.scope.actions.value = actions
        }

        initialValues = Object.assign({}, initialValues, record);


        if (record.principal) {
            initialValues.scope.principals.type = 'specific'
            initialValues.scope.principals.value = record.principal
        }


        if (record.resource) {
            initialValues.scope.resources.type = 'specific'
            initialValues.scope.resources.value = record.resource
        }
    }


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {(props) => {
                return (
                    <FormWithButtons
                        // handleSubmit={props.handleSubmit}
                        // isSubmitting={props.isSubmitting}
                        handleCancel={onCancel}
                        debug={false && stage === 'ide'} {...props}>
                        <FormComponent {...props}/>
                    </FormWithButtons>
                )
            }
            }
        </Formik>
    );
};
