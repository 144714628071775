import {useParams} from "react-router-dom";
import {usePolicyStore} from "../../policy-stores/hooks/usePolicyStore.ts";
import {useEffect} from "react";
import {FieldProps} from "formik";
import {TextInput} from "@mantine/core";

export const PolicyStoreNamespaceInput = ({
                                              field,
                                              form: {setFieldValue},
                                              ...rest
                                          }: FieldProps) => {

    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }
    const {
        record: policyStore,
        handleFetchRecord: handleFetchPolicyStore
    } = usePolicyStore(policyStoreId);

    useEffect(() => {
        handleFetchPolicyStore();
    }, [policyStoreId]);

    useEffect(() => {
        setFieldValue(field.name, policyStore.namespace);
    }, [policyStore]);

    return (
        <TextInput name={field.name} value={field.value} {...rest} />
    )

}