import {FunctionComponent} from 'react';

import {
    FormPageLayout,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {usePolicyStoreSchema} from "../hooks/usePolicyStoreSchema";
import {useParams} from "react-router-dom";
import {useDocumentTitle} from "@mantine/hooks";
import {AddRecordForm} from '../forms/AddRecordForm';

export const AddPage: FunctionComponent = () => {

    const {id: policyStoreId} = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }


    const {
        record,
        handleCreateRecord
    } = usePolicyStoreSchema(policyStoreId);

    useDocumentTitle('Add Schema');

    return (
        <FormPageLayout
            title='Add Schema'
        >
            <SimplePageContentWrapper>
                <AddRecordForm record={record} handleSubmit={handleCreateRecord}/>
            </SimplePageContentWrapper>
        </FormPageLayout>
    );
};
