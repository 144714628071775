import {
    ActionIcon,
    useMantineColorScheme,
} from '@mantine/core';

import {
    AiFillMoon,
    AiFillSun
} from "react-icons/ai";

export const ColorSchemeIcon = () => {

    const {
        colorScheme,
        toggleColorScheme,
    } = useMantineColorScheme();

    const icons = {
        light: <AiFillSun style={{
            color: '#ffffff'
        }} size={'3.5em'}/>,
        dark: <AiFillMoon style={{
            color: '#ffffff',
        }} size={'3.5em'}/>,
    } as { [k: string]: any };


    return (
        <ActionIcon
            mt={'md'}
            variant="subtle"
            onClick={() => {
                toggleColorScheme ? toggleColorScheme() : null;
            }}
            title="Toggle color scheme"
        >
            {icons[colorScheme === 'dark' ? 'light' : 'dark']}
        </ActionIcon>
    );


};
