import {
    Field,
    FormikProps
} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    Paper,
    SimpleGrid
} from '@mantine/core';

import {
    PacFormikRadioGroup,
    PacFormikSwitch
} from '@pac/platform-ui-components';
import {PlatformAccountsAutocomplete} from "../../platform-accounts/inputs/PlatformAccountsAutocomplete.tsx";
import {DeploymentEnvironment} from "../types.ts";

export const FormComponent = (_?: FormikProps<DeploymentEnvironment>) => {
    const allowedRegions = [{
        value: 'us-west-2',
        label: <Paper shadow={'sm'} p={'lg'} withBorder>
            {'US West ( Oregon ) - [ us-west-2 ]'}
        </Paper>
    }, {
        value: 'us-east-1',
        label: <Paper shadow={'sm'} p={'lg'} withBorder>
            {'US East (N.Virginia) - [ us-east-1 ]'}
        </Paper>
    }]

    return (
        <SimpleGrid cols={1}>

            <Field
                name='platformAccountId'
                label='Platform Account'
                placeholder={I18n.get('Platform Account')}
                component={PlatformAccountsAutocomplete}
                withAsterisk
                my={'xl'}
            />


            <Field
                name="region"
                label="Region"
                // description="Identity provider can be used"
                withAsterisk
                my={'xl'}
                options={allowedRegions}
                component={PacFormikRadioGroup}/>

            <Field
                name="isDefault"
                label="Make Default"
                withAsterisk
                my={'xl'}
                options={[{
                    value: true,
                    label: 'Yes'
                }, {
                    value: false,
                    label: 'No'
                }]}
                component={PacFormikSwitch}/>


        </SimpleGrid>
    );
};

