import {
    getPermissionGroup,
    listPermissionGroups
} from './queries';

import {
    createPermissionGroup,
    deletePermissionGroup,
    updatePermissionGroup
} from './mutations';

import {
    CreatePermissionGroup,
    DeletePermissionGroup,
    GetPermissionGroup,
    ListPermissionGroups,
    PermissionGroup,
    UpdatePermissionGroup
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";

const client = generateClient();

export const fetchRecords = async (policyStoreId: string, query?: { [k: string]: any }) => {

    let filter;

    const permissionProfileFilter = {
        policyStoreId: policyStoreId,
    };

    if (query && query.filter) {
        filter = {...permissionProfileFilter, ...query.filter}
    } else {
        filter = permissionProfileFilter;
    }


    try {
        const response = await client.graphql<GraphQLQuery<ListPermissionGroups>>({
                                                                                      query: listPermissionGroups,
                                                                                      variables: {
                                                                                          filter: filter
                                                                                      }
                                                                                  });

        return response.data?.listPermissionGroups.items;
    } catch (e: any) {
        notifyError(e);
    }
};

export const fetch = async (policyStoreId: string, id: string) => {
    console.debug('POLICY STORE ID', policyStoreId)
    try {
        const response = await client.graphql<GraphQLQuery<GetPermissionGroup>>({
                                                                                    query: getPermissionGroup,
                                                                                    variables: {
                                                                                        id: id
                                                                                    },
                                                                                });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getPermissionGroup;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (policyStoreId: string, payload: PermissionGroup) => {

    console.debug('FORM PAYLOAD', payload)

    const variables = Object.assign({}, {
        input: {
            ...payload,
            policyStoreId: policyStoreId
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<CreatePermissionGroup>>({
                                                                                       query: createPermissionGroup,
                                                                                       variables: variables,
                                                                                   });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Permission Group created');
            return response.data.createPermissionGroup;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const update = async (policyStoreId: string, id: string, record: PermissionGroup) => {

    console.debug('IN UPDATE PERMISSION GROUP SERVICE id ', id)

    const variables = Object.assign({}, {
        input: {
            id: id,
            name: record.name,
            description: record.description,
            policyStoreId: policyStoreId,
            entityTypeName: record.entityTypeName,
            entityIdentifier: record.entityIdentifier,
        }
    });


    try {
        const response = await client.graphql<GraphQLQuery<UpdatePermissionGroup>>({
                                                                                       query: updatePermissionGroup,
                                                                                       variables: variables,
                                                                                   });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Permission Group updated');
            return response.data.updatePermissionGroup;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const deleteRecord = async (policyStoreId: string, id: string) => {
    console.debug('IN DELETE PERMISSION GROUP POLICY STORE ID', policyStoreId)
    try {
        const response = await client.graphql<GraphQLQuery<DeletePermissionGroup>>({
                                                                                       query: deletePermissionGroup,
                                                                                       variables: {
                                                                                           input: {
                                                                                               id: id
                                                                                           }
                                                                                       },
                                                                                   });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Permission Group deleted');
            return response.data.deletePermissionGroup;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};
