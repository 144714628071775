import {
    Button,
    Title
} from '@mantine/core';
import classes from "./SignInButton.module.css";
import {AiOutlineLogin} from 'react-icons/ai';
import {signInWithRedirect} from "aws-amplify/auth";

export const SignInButton = () => {


    return (
        <Button
            leftSection={<AiOutlineLogin size={'1.5em'}/>}
            size={'md'}
            classNames={classes}
            onClick={() => {

                signInWithRedirect()

            }}>
            <Title order={6}>Sign In</Title>
        </Button>
    )


}
