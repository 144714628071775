import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          name: Yup.string()
                                                   .required('Required'),
                                          entityTypeName: Yup.string()
                                                             .required('Required'),
                                          entityIdentifier: Yup.string()
                                                               .required('Required'),
                                          policyStoreId: Yup.string()
                                      });
