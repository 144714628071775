import {
    FunctionComponent,
    useEffect
} from 'react';

import {
    PlatformLoader,
    PlatformTabbedComponent,
    PlatformTabComponentTab,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {IndexPage as ActionsIndex} from "../../actions/pages/IndexPage";
import {IndexPage as EntityTypesIndex} from "../../entity-types/pages/IndexPage";
import {IndexPage as PolicyStoreSchema} from "../../policy-store-schemas/pages/IndexPage.tsx";
import {
    useLocation,
    useParams
} from 'react-router-dom';
import {usePolicyStoreSchema} from "../../policy-store-schemas/hooks/usePolicyStoreSchema.ts";

export const SchemaPageSegment: FunctionComponent = () => {

    const {hash} = useLocation()

    const {
        id: policyStoreId,
    } = useParams();

    if (typeof policyStoreId === 'undefined') {
        throw new Error('policyStoreId is required parameter')
    }

    const {
        record: policyStoreSchema,
        handleFetchRecord,
    } = usePolicyStoreSchema(policyStoreId);

    useEffect(() => {
        handleFetchRecord();
    }, []);

    const schemaTabs = [
        // {
        //     title: 'Schema Management',
        //     component: <PolicyStoreSchema/>,
        //     key: 'schema-management'
        // },

    ] as (PlatformTabComponentTab & { key: string })[]

    if (typeof policyStoreSchema !== 'undefined' && policyStoreSchema.schema !== '{}') {
        schemaTabs.push(
            {
                title: 'Entity Types',
                component: <EntityTypesIndex/>,
                key: 'entity-types'
            },
            {
                title: 'Actions',
                component: <ActionsIndex/>,
                key: 'actions'
            },
            {
                title: 'Store Schema',
                component: <PolicyStoreSchema/>,
                key: 'schema-management'
            },
        )

    } else {
        schemaTabs.push(
            {
                title: 'Store Schema',
                component: <PolicyStoreSchema/>,
                key: 'schema-management'
            },
        )
    }

    if (!policyStoreSchema) {
        return <PlatformLoader message={'Loading schema...Please, wait!'}/>
    }


    const activeTab = schemaTabs.find((tab) => tab.key === hash.substring(1)) || schemaTabs[0]
    activeTab.active = true

    return (
        <SimplePageContentWrapper>
            <PlatformTabbedComponent tabs={schemaTabs}></PlatformTabbedComponent>
        </SimplePageContentWrapper>
    );
};
